<template>
  <div id="ApplyBonus">
    <div class="content_box">
      <div class="inner_max">
        <div>
          <h3>{{ $t('promotion.cashback.title.treeSimpleSteps') }}</h3>
          <div class="process_box">
            <div class="process" v-for="index in 3" :key="index">
              <div class="step_box">
                <div class="step">{{ index }}</div>
                <span>{{ $t(`promotion.cashback.content.stepsContent${index}`) }}</span>
              </div>
              <img
                v-if="index < 3"
                src="@/assets/images/promotion/cashback/three_green_arrow.png"
                alt="three green arrow"
              />
            </div>
          </div>
        </div>

        <div>
          <h3>{{ $t('promotion.cashback.title.depositTradEarn') }}</h3>
          <div class="earn_info">
            <p>{{ $t('promotion.cashback.content.depositTradEarnContent1') }}</p>
            <span>{{ $t('promotion.cashback.content.depositTradEarnContent2') }}</span>
            <el-button @click="$router.push('depositFunds')">{{
              $t('promotion.cashback.button.depositNow')
            }}</el-button>
          </div>
        </div>
        <img src="@/assets/images/promotion/cashback/green_arrow.png" alt="green arrow" />

        <div>
          <h3 v-if="isOptIn">{{ $t('promotion.cashback.title.bonusSummary') }}</h3>
          <h3 v-else>{{ $t('promotion.cashback.title.historicalEarnings') }}</h3>

          <div class="table_box">
            <p v-if="isOptIn">{{ $t('promotion.cashback.subTitle.tradeFxOilAndGold') }}</p>
            <p v-else>{{ $t('promotion.cashback.subTitle.seetheBonusStatus') }}</p>
            <el-table :data="displayData" stripe max-height="350">
              <el-table-column prop="date" :label="$t('promotion.cashback.column.date')" min-width="100" />

              <el-table-column prop="deposit" :label="$t('promotion.cashback.column.deposit')" min-width="100">
                <template slot-scope="scope">
                  <span>{{ returnTemplatedValue('deposit', scope.row.deposit) }}</span>
                </template>
              </el-table-column>

              <el-table-column prop="withdrawal" :label="$t('promotion.cashback.column.withdraws')" min-width="100">
                <template slot-scope="scope">
                  <span>{{ returnTemplatedValue('withdrawal', scope.row.withdrawal) }}</span>
                </template>
              </el-table-column>

              <el-table-column prop="bonus" :label="$t('promotion.cashback.column.bonus')" min-width="100">
                <template slot-scope="scope">
                  <span :class="returnClassOfColor('bonus', scope.row.bonus)">
                    {{ returnTemplatedValue('bonus', scope.row.bonus) }}
                  </span>
                </template>
              </el-table-column>

              <el-table-column prop="status" :label="$t('promotion.cashback.column.status')" min-width="100">
                <template slot-scope="scope">
                  <el-button class="applyBtn" v-if="scope.row.status === 0" @click="openDialog" type="primary">
                    {{ $t(`promotion.cashback.status.${[scope.row.status]}`) }}
                  </el-button>
                  <span v-else :class="returnClassOfColor('status', scope.row.status)">
                    {{ $t(`promotion.cashback.status.${[scope.row.status]}`) }}
                  </span>
                </template>
              </el-table-column>

              <el-table-column prop="reason" :label="$t('promotion.cashback.column.reason')" min-width="100">
                <template slot-scope="scope">
                  <span>{{ $t(`promotion.cashback.reason.${[scope.row.reason]}`) }}</span>
                </template>
              </el-table-column>

              <el-table-column prop="mt4Account" :label="$t('promotion.cashback.column.account')" min-width="100">
                <template slot-scope="scope">
                  <span>{{ `#${scope.row.mt4Account}` }}</span>
                </template>
              </el-table-column>
            </el-table>
            <Pagination :table-data="bonusHistories" :display-data.sync="displayData" />
          </div>
        </div>

        <div class="per_trades_info">
          <h3>{{ $t('promotion.cashback.title.howMuch') }}</h3>
          <PerTradesInfo :stpRatio="stpRatio" :ecnRatio="ecnRatio" />
        </div>

        <div>
          <h3>{{ $t('promotion.cashback.tnc.title') }}</h3>
          <el-collapse accordion>
            <el-collapse-item :title="$t('promotion.cashback.tnc.title')" name="1">
              <TnC />
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
      <el-dialog
        :visible.sync="showDialog"
        :top="'10%'"
        :width="'850px'"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        custom-class="vDialog"
      >
        <div class="dialog_content">
          <p>{{ $t('promotion.cashback.content.optInContent1') }}</p>
          <TnC class="tncContent" />
          <div>
            <el-button @click="apply" type="primary">{{ $t('promotion.cashback.button.agree') }}</el-button>
            <el-button @click="closeDialog">{{ $t('promotion.cashback.button.cancel') }}</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { apiCampaignInfo, apiCampaignOpt } from '@/resource';
import Pagination from '@/components/Pagination';
import PerTradesInfo from '@/components/cashback/PerTradesInfo';
import splitThousands from '@/util/splitThousands';
import TnC from '@/components/cashback/TnC';

export default {
  name: 'ApplyBonus',
  components: { Pagination, PerTradesInfo, TnC },
  data() {
    return {
      bonusHistories: [],
      displayData: [],
      stpRatio: [],
      ecnRatio: [],
      isOptIn: false,
      showDialog: false
    };
  },
  computed: {
    campaignID() {
      let campaign = this.$store.state.promotion.eligibleCampaigns.find(c => c.campaignType === 'CASHBACK_2');
      return campaign ? campaign.id : null;
    }
  },
  methods: {
    getCashbackInfo() {
      apiCampaignInfo(this.campaignID)
        .then(resp => {
          if (resp.data.code === 0) {
            this.bonusHistories = resp.data.data.bonusHistories;
            this.stpRatio = resp.data.data.stpRatio;
            this.ecnRatio = resp.data.data.ecnRatio;
            this.isOptIn = resp.data.data.isOptIn;
          }
        })
        .catch(error => {
          this.showError();
        });
    },
    apply() {
      apiCampaignOpt(this.campaignID, true)
        .then(resp => {
          if (resp.data.data) {
            this.showSuccess();
            this.closeDialog();
            this.getCashbackInfo();
          } else {
            this.showError();
          }
        })
        .catch(error => {
          this.showError();
        });
    },
    returnTemplatedValue(column, value) {
      if (!value) return '-';

      if (column === 'deposit' || column === 'withdrawal') {
        return `$${splitThousands(value)}`;
      }

      if (column === 'bonus') {
        return value > 0 ? `+$${splitThousands(value)}` : `-$${splitThousands(Math.abs(value))}`;
      }
    },
    returnClassOfColor(column, value) {
      if (column === 'bonus') {
        if (!value) return '';
        if (value > 0) return 'text-green';
        if (value < 0) return 'text-magenta';
      }
      if (column === 'status') {
        switch (value) {
          case 1:
            return 'text-green';
          case 2:
            return 'text-magenta';
          case 3:
            return 'text-harmony-gray';
          default:
            return '';
        }
      }
    },
    openDialog() {
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
    showSuccess() {
      this.$message({
        message: this.$t('common.field.successful'),
        type: 'success',
        duration: 5000
      });
    },
    showError() {
      this.$message({
        message: this.$t('common.field.failed'),
        type: 'error',
        duration: 5000
      });
    }
  },
  mounted() {
    this.getCashbackInfo();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/applyBonus.scss';
</style>
